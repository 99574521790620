import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/unauthorized.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/modules/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/modules/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
